

































































































































import { Component, Mixins } from "vue-property-decorator";
import { NavBar, Swipe, SwipeItem, Image, Row, Dialog } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import Ticketltem from "@/components/Tickets/Ticketltem.vue";
import ResultFooter from "@/views/LimitActivities/components/Fission/ResultFooter.vue";
import { Route, NavigationGuardNext } from "vue-router";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
    [Row.name]: Row,
    [Dialog.Component.name]: Dialog.Component,
    Ticketltem,
    ResultFooter,
  },
})
export default class FissionHelpResults extends Mixins(Mixin) {
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next((vm) => {
      if (from.path.includes("fission-activity")) {
        (vm as FissionHelpResults).isPage = true;
      } else {
        (vm as FissionHelpResults).isPage = false;
      }
    });
  }
  id = "";
  activeInvitationCode = "";
  showPrivacy = false;
  helpData: cms.LuckDrawHelpDto = {
    prizeName: "",
    userHeadImageUrl: "",
    userPhone: "",
  };
  isPage = false; //是否是上一级页面跳入
  couponDate: cms.LinkActivityCouponResponse = {};
  activityDescription = "";
  activityInfo: cms.ActivityInfoDto = { menuType: "" };

  mounted(): void {
    this.id = this.$route.query.id ? String(this.$route.query.id) : "";
    this.activeInvitationCode = this.$route.query.activeInvitationCode
      ? String(this.$route.query.activeInvitationCode)
      : "";
    this.getInfo();
    this.getRule();
  }

  onClickLeft(): void {
    this.$router.replace(`/fission-activity?id=${this.id}`);
  }

  getType(): number {
    let type = 2;
    if (this.couponDate.coupons && this.couponDate.coupons.length > 0) {
      type = 1;
    }
    return type;
  }

  /*
   * 获取活动规则
   */
  getRule(): void {
    this.$api.cmsApi.activityInfo.findActivityInfoById(
      this.id,
      ({ data }) => {
        this.activityInfo = data.activityInfoDto || { menuType: "" };
        this.activityDescription = data.activityInfoDto
          ? data.activityInfoDto.activityDescription || ""
          : "";
        if (this.activityInfo.activityName) {
          window.document.title = this.activityInfo.activityName;
        }
      },
      (error) => {
        if (error.includes("@_@")) {
          this.$toast(error.split("@_@")[1]);
        } else {
          error && this.$toast(error);
        }
      }
    );
  }

  /*
   * 获取信息
   */
  getInfo(): void {
    let data = {
      activeInvitationCode: this.activeInvitationCode,
      activityId: this.id,
    };
    this.$api.cmsApi.lucky.helpPrize(
      data,
      ({ data }) => {
        this.helpData = data;
        if (data.couponActiveId) {
          this.getCouponList(data.couponActiveId);
        } else {
          this.couponDate = {};
        }
        if (data.msg) {
          if (this.isPage) {
            this.helpData.prizeName = "分享好友赢大奖哦！";
          } else {
            this.helpData.prizeName = data.msg;
          }
        }
      },
      (error) => {
        if (error.includes("@_@")) {
          if (this.isPage) {
            this.helpData.prizeName = "分享好友赢大奖哦！";
          } else {
            this.helpData.prizeName = error.split("@_@")[1];
          }

          this.helpData.userHeadImageUrl = this.UserModule.headImg;
          this.helpData.userPhone = this.UserModule.phone.replace(
            /(\d{3})\d{4}(\d{4})/,
            "$1****$2"
          );
        } else {
          error && this.$toast(error);
        }
      }
    );
  }

  /*
   * 优惠卷列表
   */
  getCouponList(couponActiveId: string): void {
    this.$api.cmsApi.lucky.getLinkActivity(couponActiveId, ({ data }) => {
      this.couponDate = data;
    });
  }

  /*
   * 处理按钮
   */
  dealName(status?: string): {
    status: number;
    name: string;
  } {
    let data = {
      status: 1,
      name: "领取",
    };
    if (status === "2") {
      data.status = 1;
      data.name = "去使用";
    } else if (status === "3") {
      data.status = 2;
      data.name = "已使用";
    } else if (status === "5" || status === "4") {
      data.status = 2;
      data.name = "已过期";
    }
    return data;
  }

  /*
   * 处理按钮下的提示
   rType:receiveQuantityType(领取数量类型，1：不限制，2:每人限领多少张)
   vType:receivedType(领取类型，1可领，2已领，3领光)
   count:myReceivedCount (用户领取数量)
   quantity:receiveQuantity(限制领取数量)
   当rType为1不限制用户领取时，
      如果vType为1  展示用户"持有count张"；如果vType为2 展示用户"持有count张"；如果vType为3 啥也不展示 按钮置灰
  当rType为2时限制用户领取时
  如果vType为1 展示用户最大可领取quantity数量；如果vType为2 展示用户"持有count张/quantity张"；如果vType为3 啥也不展示 按钮置灰
   */
  dealQuantity(
    rType?: number,
    vType?: string,
    count?: number,
    quantity?: number
  ): string {
    let res = "";
    if (rType === 1) {
      //领取
      if (vType === "1") {
        //不限制
        res = count ? `持有${count}张` : "";
      } else if (vType === "2") {
        res = count ? `持有${count}张` : "";
      } else if (vType === "3") {
        res = "";
      }
    } else {
      //使用
      if (vType === "1") {
        if ((count as number) > 0) {
          res = `持有${count}/${quantity}张`;
        } else {
          res = quantity ? `可领取${quantity}张` : "";
        }
      } else if (vType === "2") {
        res = `持有${count}/${quantity}张`;
      } else {
        res = "";
      }
    }
    return res;
  }

  /*
   * 处理价格
   */
  dealPrice(price?: number): string {
    return price ? `￥${price}` : "";
  }

  /*
   * 订单金额提示
   */
  dealAmount(type?: number, Amount?: number): string {
    let name = "";
    if (type === 1) {
      name = "不限金额";
    } else {
      name = `满${Amount}可用`;
    }
    return name;
  }

  /*
   * 处理日期
   */
  dealDate(
    type?: number,
    startTime?: string,
    endTime?: string,
    effectiveDays?: number
  ): string {
    let timer = "";
    if (type === 1) {
      if (startTime && endTime) {
        timer = `有效期${startTime}至${endTime}`;
      } else {
        timer = "";
      }
    } else {
      if (effectiveDays) {
        timer = `${effectiveDays}天后过期`;
      } else {
        timer = "";
      }
    }

    return timer;
  }

  /*
   * 活动规则
   */
  activityRules(): void {
    this.showPrivacy = true;
  }
  /*
   * 去使用
   */
  goUse(): void {
    this.$router.push(`/mine/download-app`);
  }
  /*
   * 成为“锦鲤”
   */
  becomeKoi(): void {
    this.$router.push(`/mine/download-app`);
  }
}
